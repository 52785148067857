import { Center } from "../models/centers.model"

function transformCenters(centers: Center[]) {
  return centers.map((center) => {
    const { centerId, centerName, googleMapURL } = center
    if (googleMapURL) {
      const match = googleMapURL.match(/@(-?\d+\.\d+),(-?\d+\.\d+)/)
      if (match) {
        const [, lat, lng] = match
        return {
          centerId,
          centerName,
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        }
      } else {
        // Handle the case where the regex does not match
        return {
          centerId,
          centerName,
          lat: 0,
          lng: 0,
        }
      }
    } else {
      return {
        centerId,
        centerName,
        lat: 0,
        lng: 0,
      }
    }
  })
}

export default transformCenters
