import { Skeleton, Stack } from "@mui/material"
import CenterCard from "../../components/CenterCard/CenterCard"
import CommunityIcon from "../../assets/CommunityIcon.svg"
import GymIcon from "../../assets/GymIcon.svg"
import WifiIcon from "../../assets/WifiIcon.svg"
import CupIcon from "../../assets/CupIcon.svg"
import AffordableIcon from "../../assets/AffordableIcon.svg"
import LoungeIcon from "../../assets/LoungeIcon.svg"
import ClockIcon from "../../assets/ClockIcon.svg"
import SportsIcon from "../../assets/SportsIcon.svg"
import PlaystoreIcon from "../../assets/PlaystoreIcon.svg"
import AppStoreIcon from "../../assets/AppStoreIcon.svg"
import DownloadSection from "../../assets/DownloadSection.svg"
import {
  CenterGrid,
  FacilityGrid,
  FacilityHeading,
  FacilityItem,
  FacilityText,
  HeroSection,
  HeroTitle,
  PlaceholderCard,
  OurSpaceHeading,
  DownloadAppHeading,
  DownloadAppSection,
  DownloadAppText,
  DownloadAppButtons,
  DownloadAppImage,
  ContentSection,
  HeroTitleMobile,
  TermsText,
  ClusterOverlayCenterDiv,
} from "./styles"
import { APP_STORE_URL, PLAY_STORE_URL } from "../../constants/urls"
import { useGetCentersQuery } from "../../apis/centersApi"
import GoogleMapComp from "../../components/GoogleMap"
import { useEffect, useState } from "react"
import transformCenters from "../../utils/locationUtils"
import ClusterOverlay from "../../components/ClusterOverlay"
import ClusterOverlayMobile from "../../components/ClusterOverlayMobile"

const loadingCardsArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const facilities = [
  { title: "Community Events*", icon: CommunityIcon, height: "32px" },
  { title: "Gym Facilities*", icon: GymIcon, height: "20px" },
  { title: "High-Speed WiFi", icon: WifiIcon, height: "22.51px" },
  { title: "Cafe & Tea Bar*", icon: CupIcon, height: "34px" },
  { title: "Affordable", icon: AffordableIcon, height: "35px" },
  { title: "Comfort Lounges*", icon: LoungeIcon, height: "32px" },
  { title: "Quick Booking", icon: ClockIcon, height: "33px" },
  { title: "Sports Area*", icon: SportsIcon, height: "34.18px" },
]

interface LocationDetail {
  centerId: string
  centerName: string
  lat: number
  lng: number
  clusterName: string | null
}

const Centers = () => {
  const { data: centerList, isLoading, isFetching } = useGetCentersQuery([])
  const [locationDetails, setLocationDetails] = useState<LocationDetail[]>([])
  const [active, setActive] = useState("All")

  useEffect(() => {
    if (centerList?.length) {
      setLocationDetails(transformCenters(centerList))
    }
  }, [centerList])

  function smoothScrollToId(id: string, offset = 0, duration = 1000) {
    const element = document.getElementById(id)
    if (!element) return

    const targetPosition = element.offsetTop - offset
    const startPosition = window.scrollY
    const distance = targetPosition - startPosition
    const startTime = performance.now()

    function animateScroll(currentTime: number) {
      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1) // Normalize progress (0 to 1)
      const easeInOutQuad =
        progress < 0.5
          ? 2 * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 2) / 2 // Easing function

      const scrollPosition = startPosition + distance * easeInOutQuad
      window.scrollTo(0, scrollPosition)

      if (progress < 1) {
        requestAnimationFrame(animateScroll) // Continue animation until complete
      }
    }

    requestAnimationFrame(animateScroll)
  }

  return (
    <ContentSection>
      <HeroTitleMobile>
        Your own office with world-class amenities. Starting at{" "}
        <span>₹249/-!</span>
      </HeroTitleMobile>
      <HeroSection>
        <HeroTitle>
          Your own office with world-class amenities. Starting at{" "}
          <span>₹249/-!</span>
        </HeroTitle>

        <GoogleMapComp
          locationDetails={locationDetails}
          smoothScrollToId={smoothScrollToId}
          active={active}
        />
        <ClusterOverlayCenterDiv>
          <ClusterOverlay
            locationDetails={locationDetails}
            active={active}
            setActive={setActive}
          />
          <ClusterOverlayMobile
            locationDetails={locationDetails}
            active={active}
            setActive={setActive}
          />
        </ClusterOverlayCenterDiv>
      </HeroSection>
      <OurSpaceHeading>Our Spaces</OurSpaceHeading>
      {isLoading && isFetching && (
        <CenterGrid>
          {loadingCardsArr.map((item) => (
            <PlaceholderCard key={item}>
              <Stack gap={2}>
                <Skeleton
                  variant="rectangular"
                  height={150}
                  data-testid="loading-skeleton"
                />
                <Skeleton
                  variant="rectangular"
                  height={34}
                  data-testid="loading-skeleton"
                />
              </Stack>
            </PlaceholderCard>
          ))}
        </CenterGrid>
      )}
      <CenterGrid>
        {centerList &&
          centerList
            .map((center) => {
              return <CenterCard key={center.centerId} center={center} />
            })
            .reverse()}
      </CenterGrid>
      <FacilityHeading>Why Choose us?</FacilityHeading>
      <FacilityGrid>
        {facilities.map((facility) => (
          <FacilityItem key={facility.title}>
            <img
              src={facility.icon}
              alt={facility.title}
              height={facility.height}
            />
            <FacilityText>{facility.title}</FacilityText>
          </FacilityItem>
        ))}
      </FacilityGrid>
      <TermsText>T&C Apply* - Chargeable basis</TermsText>
      <DownloadAppHeading>Download our app now</DownloadAppHeading>
      <DownloadAppSection>
        <DownloadAppImage src={DownloadSection} alt="download-placeholders" />
        <DownloadAppText>
          Boost your productivity with the BHIVE Workspace app. Elevate your
          workspace, collaborate efficiently, and unlock exclusive perks.
        </DownloadAppText>
        <DownloadAppButtons>
          <img
            src={PlaystoreIcon}
            alt="play-store-icon"
            height="45px"
            onClick={() => window.open(PLAY_STORE_URL)}
          />
          <img
            src={AppStoreIcon}
            alt="app-store-icon"
            height="45px"
            onClick={() => window.open(APP_STORE_URL)}
          />
        </DownloadAppButtons>
      </DownloadAppSection>
    </ContentSection>
  )
}

export default Centers
