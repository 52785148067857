import StaticPageHero from "../../components/StaticPageHero/StaticPageHero"
import { Container, ParentContainer } from "./styles"

const RefundPolicy = () => {
  return (
    <ParentContainer>
      <StaticPageHero text="Refund Policy" />
      <Container>
        <p>
          <strong>Effective Date: 18th October, 2024</strong>
        </p>
        <p>
          This Refund Policy (“Policy”) applies to{" "}
          <strong>
            <u>
              <span>
                BHIVE Zenith LLP (“ZENITH”), a company incorporated under the
                Indian Companies Act 2013
              </span>
            </u>
          </strong>{" "}
          having its registered office at{" "}
          <strong>
            No. 148, BBMP No. 170/148, 5th Main Road, Sector – 6, HSR Layout,
            Bangalore – 560102
          </strong>{" "}
          <strong>
            <u>
              <span>
                ,operating under the brand name/s of BHIVE Workspace/ Garuda
                BHIVE Workspace/ BHIVE Premium/ BHIVE Platinum ( hereinafter
                collectively referred to as “BHIVE”/ “We”/ “Us”).
              </span>
            </u>
          </strong>
        </p>
        <p>
          All payments made under this Policy, subsequently captured in any
          agreement, are irrevocable, non-refundable, and non-creditable. Once a
          payment is processed, it cannot be refunded under any circumstances.
        </p>
        <strong>
          <u>Know Your Customer (KYC) Compliance</u>
        </strong>
        <p>
          <strong>
            <u>Service Provision</u>
          </strong>
        </p>
        <p>
          Services will be provided only after the successful completion of the
          Know Your Customer (KYC) process and verification of the customer's
          identity.
        </p>
        <p>
          <strong>
            <u>Customer Responsibility</u>
          </strong>
        </p>
        <p>
          It is the customer's responsibility to complete the KYC process within
          the specified timeframe provided by BHIVE.
        </p>
        <p>
          <strong>
            <u>Failure to Complete KYC</u>
          </strong>
        </p>
        <p>
          In the event that the customer fails to complete the KYC process
          within the specified timeframe:
        </p>
        <p>
          - BHIVE reserves the right to cancel the customer's request for
          services.
          <br />
          - The customer will not be entitled to any refund of payments already
          made.
          <br />- No services will be provided until the KYC process is
          completed.
        </p>
        <p>
          <strong>
            <u>Non-Provision of Services</u>
          </strong>
        </p>
        <p>
          BHIVE is not obligated to provide any services until all
          prerequisites, including but not limited to KYC verification, are
          fulfilled by the customer.
        </p>
        <p>
          <strong>
            <u>Acceptance of Terms</u>
          </strong>
        </p>
        <p>
          By making a payment to BHIVE , the customer acknowledges and agrees to
          the terms outlined in this Refund Policy.
        </p>
        <p>
          <strong>
            <u>Changes to the Policy</u>
          </strong>
        </p>
        <p>
          BHIVE reserves the right to modify or update this Policy at any time
          without prior notice. Changes will be effective immediately upon
          posting on BHIVE’s website.
        </p>
        <p>
          <strong>
            <u>Governing Law</u>
          </strong>
        </p>
        <p>
          This Policy is governed by the laws of India. Any disputes arising
          from this policy shall be subject to the exclusive jurisdiction of the
          courts located in Bangalore, Karnataka.
        </p>
        <p>
          <strong>
            <u>Contact Information</u>
          </strong>
        </p>
        <p>
          {" "}
          If You have any questions or concerns regarding this Policy, please
          contact at the below details:
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:sales@bhiveworkspace.com">sales@bhiveworkspace.com</a>
          <br />
          <strong>Phone:</strong>{" "}
          <a href="tel:+919538677774">+91 95386 77774</a>
          <br />
          <strong>Address:</strong> BHIVE Workspace (Office Space & Coworking),
          L-148, 5th Main Rd, Sector 6, HSR Layout, Bengaluru, Karnataka 560102
        </p>
        <hr />
        <p>
          <em>
            By proceeding with the payment, you confirm that you have read,
            understood, and agree to abide by this Refund Policy.
          </em>
        </p>
      </Container>
    </ParentContainer>
  )
}

export default RefundPolicy
