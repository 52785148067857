import {
  PRIVACY_POLICY,
  REFUND_POLICY,
  TERMS_AND_CONDITIONS,
} from "../../constants/path"
import { Wrapper } from "./styles"

const Footer = () => {
  return (
    <Wrapper>
      <p>
        © 2014 – 2024 <span>BHIVE Zenith LLP</span> | All Rights Reserved
      </p>
      <p>
        <a href={PRIVACY_POLICY}>Privacy Policy</a> |{" "}
        <a href={TERMS_AND_CONDITIONS}>Terms and Conditions</a> |{" "}
        <a href={REFUND_POLICY}>Refund Policy</a>
      </p>
    </Wrapper>
  )
}

export default Footer
