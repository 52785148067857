import { AppBar, styled } from "@mui/material"
import theme from "../../theme"

export const StyledAppBar = styled(AppBar)`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background: ${theme.palette.background.paper};
  padding: 0 120px;
  z-index: 100;
  box-shadow: 0px 1px 3px 1px ${theme.palette.neutrals[4]};

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    padding: 20px;
  }

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    padding: 20px;
    height: 75px;
  }
`

export const Logo = styled("img")`
  cursor: pointer;
  width: 125px;
  height: 40px;
`

export const StyledAnchorTag = styled("a")`
  border: 1px solid ${theme.palette.primary.main};
`
