import { useEffect, useState } from "react"
import { ClusterButton, ClusterButtonSingle, ClusterContainer } from "./styles"
import theme from "../../theme"

interface IClusterProps {
  locationDetails: LocationDetail[]
  active: string
  setActive: (active: string) => void
  // Add other properties of LocationDetail here
}

interface LocationDetail {
  centerId: string
  centerName: string
  lat: number
  lng: number
  clusterName: string | null
}

const ClusterOverlay = ({
  locationDetails,
  active,
  setActive,
}: IClusterProps) => {
  const [clusterNames, setClusterNames] = useState<string[]>([])
  useEffect(() => {
    const uniqueClusterNames = [
      ...new Set(
        locationDetails
          .map((location: any) => location && location.clusterName)
          .filter((name) => name !== null && name !== undefined),
      ),
    ]
    setClusterNames(uniqueClusterNames)
  }, [locationDetails])
  return (
    <ClusterContainer>
      <ClusterButtonSingle
        onClick={() => setActive("All")}
        sx={{
          borderColor:
            active === "All"
              ? theme.palette.primary.main
              : theme.palette.neutrals[3],
        }}
        variant="outlined"
      >
        All
      </ClusterButtonSingle>
      {clusterNames.map((clusterName) => (
        <ClusterButton
          onClick={() => setActive(clusterName)}
          sx={{
            borderColor:
              active === clusterName
                ? theme.palette.primary.main
                : theme.palette.neutrals[3],
          }}
          key={clusterName}
          variant="outlined"
        >
          {clusterName}
        </ClusterButton>
      ))}
    </ClusterContainer>
  )
}

export default ClusterOverlay
