import { Button, Checkbox, Divider, Stack, Typography } from "@mui/material"
import {
  AddButtonContainer,
  BookingDetailsRow,
  BookingDetailsRowItem,
  BookingText,
  CapsSpan,
  CheckboxTextContainer,
  CheckBoxWrapper,
  ConfirmButton,
  GstCheckboxContainer,
  GstContainer,
  HeaderText,
  LocationIconContainer,
  PriceBeakupRow,
  PriceBeakupSubText,
  PriceBeakupText,
  StepperCardBody,
  StepperCardFooter,
  StepperCardHeader,
  StyledTypography,
  SubHeaderText,
  TotalRowText,
  TruncatedLinkContainer,
  TruncatedText,
  UnderlinedSpan,
  ValidTextDiv,
  VerifiedUserName,
  VerticalDivider,
} from "./styles"
import VerifiedUserIcon from "../../../assets/VerifiedUserIcon.svg"
import BookingSummaryIcon from "../../../assets/BookingSummaryIcon.svg"
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined"
import { BookingState, selectBooking } from "../../../slices/bookingSlice"
import { useAppSelector } from "../../../app/hooks"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined"
import { DayType, DaypassType, URLParams } from "../../../constants/enums"
import dayjs from "dayjs"
import { calculateDaypassValues } from "../../../utils/calculateDaypassValues"
import {
  useGiftDaypassMutation,
  usePaymentFailedMutation,
  usePaymentSuccessMutation,
} from "../../../apis/bookingApi"
import theme from "../../../theme"
import { BOOKING_SUCCESS } from "../../../constants/path"
import { selectUser } from "../../../slices/userSlice"
import { useEffect, useState } from "react"
import TermsModal from "../../../components/TermsModal/TermsModal"
import { useGetCenterByIdQuery } from "../../../apis/centersApi"
import isEmpty from "lodash/isEmpty"
import { BackButton } from "../styles"
import GstModal from "../../../components/GstModal"

type BookingSummaryProps = {
  daypassPrice: number | undefined
  discountsPercentage: number | undefined
}

export interface UserState {
  accessToken: string
  userId: string
  mobile: string
  firstName: string
  LastName: string
  gstDetails: {
    gstin?: string
    companyAddress?: string
    companyName?: string
    pincode?: string
  }
}

const label = { inputProps: { "aria-label": "Terms Checkbox" } }
const gstLabel = { inputProps: { "aria-label": "GST Checkbox" } }

const BookingSummary = ({
  daypassPrice,
  discountsPercentage,
}: BookingSummaryProps) => {
  const navigate = useNavigate()
  const [openTermsModal, setOpenTermsModal] = useState(false)
  const [checkAcceptTerms, setCheckAcceptTerms] = useState(true)
  const [checkGstDetails, setCheckGstDetails] = useState(false)
  const [isGstModalOpen, setIsGstModalOpen] = useState(false)
  const { userData }: any = useAppSelector(selectUser)
  const { centerId = "" } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const passType = searchParams.get(URLParams.PASS_TYPE)

  const { data: centerDetails } = useGetCenterByIdQuery({ centerId })

  const { day, numberOfPasses, mobileNumbers, mobileNumber, bookingForSelf } =
    useAppSelector(selectBooking) as BookingState

  const {
    membersAmount = 0,
    discountAmount = 0,
    taxAmount = 0,
    totalAmount = 0,
  } = daypassPrice && discountsPercentage
    ? calculateDaypassValues(
        daypassPrice,
        discountsPercentage,
        numberOfPasses,
        18,
        passType === DaypassType.BULKDAYPASS ? true : false,
      )
    : {}

  const [giftDaypassTrigger] = useGiftDaypassMutation()
  const [paymentSuccessTrigger] = usePaymentSuccessMutation()
  const [paymentFailedTrigger] = usePaymentFailedMutation()

  const handleToggleTermsModal = () => {
    setOpenTermsModal((prev) => !prev)
  }

  const handleToggleGstModal = () => {
    setIsGstModalOpen((prev) => !prev)
  }

  const paynowHandler = async () => {
    try {
      const paymentOrderDetails = await giftDaypassTrigger({
        recipients:
          numberOfPasses === 1 && bookingForSelf
            ? [{ mobile: mobileNumber }]
            : mobileNumbers.map((num) => ({ mobile: num })),
        passType: passType === DaypassType.BULKDAYPASS ? 10 : 1,
        centerId,
        bookingDate:
          day === DayType.TODAY
            ? dayjs().format("YYYY-MM-DD")
            : dayjs().add(1, "day").format("YYYY-MM-DD"),
        ...(checkGstDetails === true && {
          booking: {
            gstDetails: {
              gstin: (userData?.gstDetails as UserState["gstDetails"])?.gstin,
              companyAddress: (userData?.gstDetails as UserState["gstDetails"])
                ?.companyAddress,
              companyName: (userData?.gstDetails as UserState["gstDetails"])
                ?.companyName,
              pincode: (userData?.gstDetails as UserState["gstDetails"])
                ?.pincode,
            },
          },
        }),
      }).unwrap()

      if (paymentOrderDetails?.id) {
        const options = {
          key: import.meta.env.PROD
            ? import.meta.env.RAZORPAY_SECRET
            : import.meta.env.RAZORPAY_TEST_KEY,
          order_id: paymentOrderDetails?.id,
          amount: paymentOrderDetails?.amount,
          currency: paymentOrderDetails?.currency,
          name: "BHIVE Workspace",
          description:
            passType === DaypassType.BULKDAYPASS
              ? "Bulk Daypass Booking"
              : "Daypass Booking",
          image: import.meta.env.RAZORPAY_IMAGE_URL,
          handler: async function (response: {
            razorpay_payment_id: string
            razorpay_order_id: string
            razorpay_signature: string
          }) {
            try {
              await paymentSuccessTrigger({
                razorpayOrderId: response.razorpay_order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpaySignature: response.razorpay_signature,
              }).unwrap()
              navigate(BOOKING_SUCCESS, {
                replace: true,
                state: {
                  bookingCodes: paymentOrderDetails.bookingCodes.join(","),
                },
              })
            } catch (error) {
              console.error("Error handling payment success:", error)
              // Handle error appropriately
            }
          },
          modal: {
            ondismiss: function () {
              paymentFailedTrigger({
                razorpayOrderId: paymentOrderDetails.id,
                reason: "user decline the payment",
              })
            },
          },
          prefill: {
            email: paymentOrderDetails?.email,
            name: `${paymentOrderDetails?.firstName} ${paymentOrderDetails?.LastName}`,
            contact: paymentOrderDetails?.mobile,
          },
          theme: { color: theme.palette.primary.main || "#ffcf4b" },
        }

        const paymentObject = new (window as any).Razorpay(options)
        paymentObject.open()
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  useEffect(() => {
    if (!isEmpty(userData?.gstDetails?.gstin)) setCheckGstDetails(true)
  }, [userData?.gstDetails])

  return (
    <>
      {openTermsModal && (
        <TermsModal open={openTermsModal} onClose={handleToggleTermsModal} />
      )}
      <StepperCardHeader>
        <img src={BookingSummaryIcon} height="48px" alt="" />
        <VerifiedUserName>
          <img src={VerifiedUserIcon} height="24px" alt="" />
          <Typography>
            {userData?.firstName
              ? `${userData?.firstName} ${userData?.LastName}`
              : userData?.mobile}
          </Typography>
        </VerifiedUserName>
      </StepperCardHeader>
      <StepperCardBody>
        <BookingText>Booking Details</BookingText>
        <TruncatedLinkContainer
          href={centerDetails?.googleMapURL || "#"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LocationIconContainer>
            <PinDropOutlinedIcon sx={{ fontSize: 14 }} />
          </LocationIconContainer>
          <TruncatedText>{centerDetails?.centerAddress}</TruncatedText>
        </TruncatedLinkContainer>
        <BookingDetailsRow>
          <BookingDetailsRowItem>
            <CalendarTodayOutlinedIcon />
            <CapsSpan>
              {day === DayType.TODAY
                ? dayjs().format("D MMM")
                : dayjs().add(1, "day").format("D MMM")}
              , {day}
            </CapsSpan>
          </BookingDetailsRowItem>
          <VerticalDivider />
          <BookingDetailsRowItem>
            <ConfirmationNumberOutlinedIcon />
            <CapsSpan>
              {passType === DaypassType.DAYPASS ? "Day Pass" : "Bulk Day Pass"}
            </CapsSpan>
          </BookingDetailsRowItem>
          <VerticalDivider />
          <BookingDetailsRowItem>
            <PeopleAltOutlinedIcon />
            <CapsSpan>
              {numberOfPasses} Member{numberOfPasses > 1 ? "s" : ""}
            </CapsSpan>
          </BookingDetailsRowItem>
        </BookingDetailsRow>
        <Divider />
        <PriceBeakupText>Price Breakup</PriceBeakupText>
        <Stack gap="8px">
          <PriceBeakupRow>
            <PriceBeakupSubText>Base Price / Member</PriceBeakupSubText>
            <PriceBeakupSubText>₹ {daypassPrice}</PriceBeakupSubText>
          </PriceBeakupRow>
          <PriceBeakupRow>
            <PriceBeakupSubText>
              {daypassPrice &&
                `${daypassPrice} ${
                  DaypassType.BULKDAYPASS === passType ? "x 10 days" : ""
                } x ${numberOfPasses} members`}
            </PriceBeakupSubText>
            <PriceBeakupSubText>₹ {membersAmount}</PriceBeakupSubText>
          </PriceBeakupRow>
          {passType === DaypassType.BULKDAYPASS && (
            <PriceBeakupRow>
              <PriceBeakupSubText>
                {discountsPercentage && discountsPercentage}% Discount
              </PriceBeakupSubText>
              <PriceBeakupSubText>₹ {discountAmount}</PriceBeakupSubText>
            </PriceBeakupRow>
          )}
          <PriceBeakupRow>
            <PriceBeakupSubText>18% GST</PriceBeakupSubText>
            <PriceBeakupSubText>₹ {taxAmount}</PriceBeakupSubText>
          </PriceBeakupRow>
          <Divider />
        </Stack>
        <PriceBeakupRow>
          <TotalRowText>
            Total <span>(Including Taxes)</span>
          </TotalRowText>
          <TotalRowText>₹ {totalAmount}</TotalRowText>
        </PriceBeakupRow>
        <Divider />
        {isEmpty(userData?.gstDetails?.gstin) ? (
          <GstContainer>
            <GstCheckboxContainer>
              <Checkbox
                {...gstLabel}
                sx={{ paddingLeft: "0px" }}
                size="small"
                checked={checkGstDetails}
                disabled={isEmpty(userData?.gstDetails?.gstin)}
                onChange={() => setCheckGstDetails((prev) => !prev)}
              />
              <CheckboxTextContainer>
                <HeaderText>Add GSTIN</HeaderText>
                <SubHeaderText>
                  Claim GST input credit on your purchase
                </SubHeaderText>
              </CheckboxTextContainer>
            </GstCheckboxContainer>
            <AddButtonContainer>
              <Button variant="text" onClick={handleToggleGstModal}>
                <AddIcon sx={{ fontSize: 18 }} />
                <Typography sx={{ fontSize: 18 }}>Add</Typography>
              </Button>
            </AddButtonContainer>
          </GstContainer>
        ) : (
          <GstContainer>
            <GstCheckboxContainer>
              <Checkbox
                {...gstLabel}
                sx={{ paddingLeft: "0px" }}
                size="small"
                checked={checkGstDetails}
                onChange={() => setCheckGstDetails((prev) => !prev)}
              />
              <CheckboxTextContainer>
                <HeaderText>
                  GSTIN: {(userData?.gstDetails as { gstin: string })?.gstin}
                </HeaderText>
                <SubHeaderText>
                  {
                    (userData?.gstDetails as { companyName: string })
                      ?.companyName
                  }
                </SubHeaderText>
              </CheckboxTextContainer>
            </GstCheckboxContainer>
            <AddButtonContainer>
              <Button variant="text" onClick={handleToggleGstModal}>
                <EditIcon sx={{ fontSize: 18 }} />
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Button>
            </AddButtonContainer>
          </GstContainer>
        )}

        <Divider />
        <GstModal
          open={isGstModalOpen}
          handleToggleGstModal={handleToggleGstModal}
          userData={userData}
        />
        <ValidTextDiv>
          {passType === DaypassType.BULKDAYPASS
            ? `Valid only for the next 30 days`
            : `Valid only for ${day}`}
        </ValidTextDiv>
      </StepperCardBody>
      <>
        <CheckBoxWrapper>
          <Checkbox
            sx={{ paddingLeft: "0px" }}
            size="small"
            {...label}
            checked={checkAcceptTerms}
            onChange={() => setCheckAcceptTerms((prev) => !prev)}
          />
          <StyledTypography>
            I accept the{" "}
            <UnderlinedSpan onClick={handleToggleTermsModal}>
              terms and conditions
            </UnderlinedSpan>
          </StyledTypography>
        </CheckBoxWrapper>
        <StepperCardFooter>
          <BackButton
            variant="outlined"
            color="primary"
            onClick={() =>
              setSearchParams((params) => {
                params.set(URLParams.ACTIVE_STATE, "2")
                return params
              })
            }
            sx={{ mr: 1 }}
          >
            Back
          </BackButton>
          <ConfirmButton
            variant="contained"
            disabled={!checkAcceptTerms}
            onClick={paynowHandler}
          >
            Pay Now
          </ConfirmButton>
        </StepperCardFooter>
      </>
    </>
  )
}

export default BookingSummary
