import { Logo, StyledAnchorTag, StyledAppBar } from "./styles"
import BhiveLogo from "../../assets/BhiveLogo.svg"
import { CENTERS } from "../../constants/path"
import CallIcon from "@mui/icons-material/Call"
import { IconButton } from "@mui/material"

const Header = () => {
  return (
    <StyledAppBar elevation={0}>
      <Logo
        src={BhiveLogo}
        alt="BhiveLogo"
        onClick={() => window.open(CENTERS, "_self")}
      />

      <StyledAnchorTag href="tel:+919538677774">
        <IconButton aria-label="call-icon" color="primary">
          <CallIcon />
        </IconButton>
      </StyledAnchorTag>
    </StyledAppBar>
  )
}

export default Header
