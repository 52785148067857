import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"
import MapIcon from "../../assets/Option-5.svg"
import { memo } from "react"
import theme from "../../theme"

interface IMapProps {
  locationDetails: LocationDetail[]
  smoothScrollToId: (id: string, offset?: number, duration?: number) => void
}

interface LocationDetail {
  centerId: string
  centerName: string
  lat: number
  lng: number
}

const center = {
  lat: 12.9466,
  lng: 77.6364,
}

const GoogleMapComp = memo(
  ({ locationDetails, smoothScrollToId }: IMapProps) => {
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
    })

    return isLoaded ? (
      <GoogleMap
        center={center}
        zoom={12}
        mapContainerStyle={{
          height: window.innerWidth < 900 ? 300 : 400,
          width: 700,
          minWidth: window.innerWidth < 900 ? 300 : 600,
          margin: window.innerWidth < 600 ? 10 : 20,
          border: `2px solid ${theme.palette.neutrals[5]}`,
          borderRadius: 8,
        }}
        options={{
          disableDefaultUI: true,
          styles: [
            {
              featureType: "poi", // Targets all points of interest
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "poi",
              elementType: "labels.text", // Keeps only the labels
              stylers: [{ visibility: "on" }],
            },
          ],
        }}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {locationDetails.map((location: LocationDetail) => (
          <Marker
            key={location.centerId}
            position={{ lat: location.lat, lng: location.lng }}
            title={location.centerName}
            icon={{
              url: MapIcon,
              scaledSize: new google.maps.Size(32, 32),
            }}
            onClick={() =>
              smoothScrollToId(
                location.centerId,
                window.innerWidth < 600 ? 80 : 100,
                2000,
              )
            }
          />
        ))}
        <></>
      </GoogleMap>
    ) : (
      <></>
    )
  },
)

export default GoogleMapComp
